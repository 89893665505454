import React, {useCallback, useMemo} from 'react';
import cn from 'classnames';
import AntDMenu from 'antd/es/menu';
import MenuItemTitle from './MenuItemTitle';
import Notification from './Notification';
import menuStyles from './CommonMenu.module.scss';
import styles from './RightMenu.module.scss';
import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import {isManager, isOwner} from '../../../type/Me';

interface Props {
  importAllowed?: string;
}

const RightMenu: React.FC<Props> = ({importAllowed}) => {
  const isCurrentUserOwner = safeLocalStorage.currentUser && isOwner(safeLocalStorage.currentUser);
  const isCurrentUserManager = safeLocalStorage.currentUser && isManager(safeLocalStorage.currentUser);

  const hasAccessToImports = useMemo(
    () => {
      if (isCurrentUserOwner) {
        return true;
      }
      if (isCurrentUserManager) {
        return importAllowed !== 'owner';
      }
      return importAllowed === 'public';
    }, [importAllowed, isCurrentUserManager, isCurrentUserManager],
  );

  const handleAddActivityClick = useCallback(() => {
    window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
  }, []);
  const handleAddCompanyClick = useCallback(() => {
    window.refreshDom({'NavBar.showCreateCompanyModal': true, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false});
  }, []);
  const handleAddDealClick = useCallback(() => {
    window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': true, 'NavBar.showCreatePersonModal': false});
  }, []);
  const handleAddPersonClick = useCallback(() => {
    window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': true});
  }, []);

  return (
    <AntDMenu
      className={cn(menuStyles.menu, styles.menu)}
      mode="horizontal"
      // @ts-ignore
      triggerSubMenuAction="click"
    >
      <AntDMenu.SubMenu
        className={menuStyles.topItem}
        popupClassName={menuStyles.submenu}
        key="add"
        popupOffset={[20, 8]}
        title={<MenuItemTitle icon="fa-plus" iconType="fas" />}
      >
        <AntDMenu.Item key="addCompany">
          <MenuItemTitle onClick={handleAddCompanyClick}>
            Add Company
          </MenuItemTitle>
        </AntDMenu.Item>
        <AntDMenu.Item key="addPerson">
          <MenuItemTitle onClick={handleAddPersonClick}>
            Add Person
          </MenuItemTitle>
        </AntDMenu.Item>
        <AntDMenu.Item key="addDeal">
          <MenuItemTitle onClick={handleAddDealClick}>
            Add Deal
          </MenuItemTitle>
        </AntDMenu.Item>
        <AntDMenu.Item key="addActivity">
          <MenuItemTitle onClick={handleAddActivityClick}>
            Add Activity
          </MenuItemTitle>
        </AntDMenu.Item>
        {hasAccessToImports && <AntDMenu.Divider />}
        {hasAccessToImports &&
          <AntDMenu.Item key="import">
            <MenuItemTitle legacyPath="/import" path="/settings/data/new-import">
              Import
            </MenuItemTitle>
          </AntDMenu.Item>
        }
      </AntDMenu.SubMenu>
      <AntDMenu.Item
        className={menuStyles.topItem}
        key="notifications"
      >
        <Notification />
      </AntDMenu.Item>
      <AntDMenu.Item
        className={menuStyles.topItem}
        key="settings"
      >
        <MenuItemTitle icon="fa-cog" iconType="fas" path="/settings" />
      </AntDMenu.Item>
    </AntDMenu>
  );
};

export default RightMenu;
