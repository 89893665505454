import get from 'lodash/get';
import invert from 'lodash/invert';
import debounce from 'lodash/debounce';
import analyticsService from '../shared-services/analytics-service';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import mapActions from '../store/store-helpers';
import routingNetworkService from '../network-services/routing-network-service';
import addEditNetworkService from '../network-services/add-edit-network-service';
import {getEntityDisplayName} from '../common/field-model/formatter';
import EntityType from '../react/type/EntityType';
import {doesUseMiles} from '../common/settings';
import userRecentNetworkService from '../network-services/user-recent-network-service';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

export default function MappingCtrl(
  $scope, $route, MainService, MappingService, BaseController,
  CustomerService, TerritoriesService, RoutingService, ImportService, LassoService,
  FetchCRMDataService, CacheService, TeamService, AccountsService, AllContactsService,
  GroupsService, CrmActivityTypesNetworkService, FunnelService, LeadGenService, mmcConst,
  $timeout,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  const page = $route.current.id;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  const shouldFindLeads = window.location.hash.includes('findLeads');

  mapActions($scope, ['RoutingService', 'modals']);
  $scope.modalsActions.resetVisibility('noAccountsModal');
  $scope.modalsActions.resetVisibility('noContactsModal');
  $scope.modalsActions.resetVisibility('noRoutesModal');
  $scope.modalsActions.resetVisibility('noGroupsModal');
  $scope.modalsActions.resetVisibility('noTerritoriesModal');

  const isOnRoutesPage = () => UTILS.isOnPage('routingPage', 'individualAccountsRoutePage', 'individualContactsRoutePage', 'routeCreatePage', 'accountsRoutingMapPage', 'contactsRoutingMapPage');

  $scope.entityType = window.isOnPage('accounts') ? EntityType.COMPANY : EntityType.PERSON;

  // attach functions to $scope
  $scope.$onInit = $onInit;
  $scope.attachScopeBindings = attachScopeBindings;
  $scope.setLeadsView = setLeadsView;
  $scope.loadGroups = loadGroups;
  $scope.loadMap = loadMap;
  $scope.fetchAccounts = fetchAccounts;
  $scope.zoomEditTerr = zoomEditTerr;
  $scope.applyFiltersOnMap = applyFiltersOnMap;
  $scope.addLocalCustomer = addLocalCustomer;
  $scope.populateLeadGenAccount = populateLeadGenAccount;
  $scope.numGroupCustomers = numGroupCustomers;
  $scope.getNumGroupPins = getNumGroupPins;
  $scope.getNumGroupsLeft = getNumGroupsLeft;
  $scope.numPinsLeft = numPinsLeft;
  $scope.showToolsMenu = showToolsMenu;
  $scope.findMeButton = findMeButton;
  $scope.unsubscribeFromAllEmails = unsubscribeFromAllEmails;
  $scope.localCustomerSearch = localCustomerSearch;
  $scope.hideSearchBoxThenScrollThenShowCustomerInfoAndZoom = hideSearchBoxThenScrollThenShowCustomerInfoAndZoom;
  $scope.hideBelowMapDivStuff = hideBelowMapDivStuff;
  $scope.print = print;
  $scope.selectedCustomer = selectedCustomer;
  $scope.sortCustomers = sortCustomers;
  $scope.selectedLocalCustomer = selectedLocalCustomer;
  $scope.searchFromMultipleContactsList = searchFromMultipleContactsList;
  $scope.closePopOver = closePopOver;
  $scope.popUpClicked = popUpClicked;
  $scope.changedComparator = changedComparator;
  $scope.saveNewFoundCustomer = saveNewFoundCustomer;
  $scope.saveCustomer = saveCustomer;
  $scope.editCustomField = editCustomField;
  $scope.saveNewCustomFieldName = saveNewCustomFieldName;
  $scope.deleteCustomField = deleteCustomField;
  $scope.cancelLassoCompletely = cancelLassoCompletely;
  $scope.toggleBulkEdit = toggleBulkEdit;
  $scope.saveLassoedPins = saveLassoedPins;
  $scope.goToCheckSheet = goToCheckSheet;
  $scope.onboardImportSheet = onboardImportSheet;
  $scope.onboardImportTips = onboardImportTips;
  $scope.saveNewRoute = saveNewRoute;
  $scope.editStartLocation = editStartLocation;
  $scope.editEndLocation = editEndLocation;
  $scope.editRouteType = editRouteType;
  $scope.chooseRouteStartingPoint = chooseRouteStartingPoint;
  $scope.chooseRouteTimePerStop = chooseRouteTimePerStop;
  $scope.sortRoutes = sortRoutes;
  $scope.cancelRoute = cancelRoute;
  $scope.closeRoute = closeRoute;
  $scope.toggleShowHideRoutesPin = toggleShowHideRoutesPin;
  $scope.editRoute = editRoute;
  $scope.submitRoute = submitRoute;
  $scope.resetAllFilters = resetAllFilters;
  $scope.saveGroupColor = saveGroupColor;
  $scope.toggleGroupsForFilter = toggleGroupsForFilter;
  $scope.openGroupsList = openGroupsList;
  $scope.refreshNearbyResults = refreshNearbyResults;
  $scope.mapToolsFinishLoading = mapToolsFinishLoading;
  $scope.closeAccount = closeAccount;
  $scope.toggleCadenceColors = toggleCadenceColors;
  $scope.closeGroupDetail = closeGroupDetail;
  $scope.chooseGroupsFromLasso = chooseGroupsFromLasso;
  $scope.rebuildRoute = rebuildRoute;
  $scope.saveRoute = saveRoute;
  $scope.findMe = findMe;
  $scope.routingGoBack = routingGoBack;
  $scope.updateAddressDetails = updateAddressDetails;
  $scope.updateStartTime = updateStartTime;
  $scope.updateStartDate = updateStartDate;
  $scope.routeStyleChoose = routeStyleChoose;
  $scope.finishRouteCreation = finishRouteCreation;
  $scope.clearRouteMarkers = clearRouteMarkers;
  $scope.showCadenceModal = showCadenceModal;
  $scope.showAreaModal = showAreaModal;
  $scope.showAddToRouteModal = showAddToRouteModal;
  $scope.showSmartRouteGroupModal = showSmartRouteGroupModal;
  $scope.closeSmartWelcomeModal = closeSmartWelcomeModal;
  $scope.closeSmartAreaModal = closeSmartAreaModal;
  $scope.detailsPayload = detailsPayload;
  $scope.contactsPayload = contactsPayload;
  $scope.accountsPayload = accountsPayload;
  $scope.saveTerritoryOnTerritoriesPage = saveTerritoryOnTerritoriesPage;
  $scope.getSearchCategories = getSearchCategories;
  $scope.leadGenSelectMultiple = leadGenSelectMultiple;
  $scope.saveLeadGenMultipleRecords = saveLeadGenMultipleRecords;
  $scope.selectLeadGenRecords = selectLeadGenRecords;
  $scope.saveNewSearches = saveNewSearches;
  $scope.unhighlightMapPins = unhighlightMapPins;
  $scope.closeAddEditTerritory = closeAddEditTerritory;
  $scope.clearTerritoryMarkers = clearTerritoryMarkers;
  $scope.cancelTerritoryBounds = cancelTerritoryBounds;
  $scope.territoryNameInputChanged = territoryNameInputChanged;
  $scope.closeEditZipsPopup = closeEditZipsPopup;
  $scope.updateUi = updateUi;
  $scope.detailsChanged = detailsChanged;
  $scope.getCurrentLocation = getCurrentLocation;
  $scope.resetFindNewCustomers = resetFindNewCustomers;
  $scope.fetchTerritories = fetchTerritories;
  $scope.saveTerritory = saveTerritory;
  $scope.saveTerritoryBounds = saveTerritoryBounds;
  $scope.saveTerritoryZips = saveTerritoryZips;
  $scope.togglePin = MappingService.togglePin;
  $scope.addMarkersToRoute = addMarkersToRoute;
  $scope.removeMarkersFromRoute = removeMarkersFromRoute;
  $scope.openSmartNameModal = openSmartNameModal;
  $scope.handlePinClicksForRouting = handlePinClicksForRouting;
  $scope.togglePinFromRoute = togglePinFromRoute;
  $scope.showFindNewCustomers = showFindNewCustomers;
  $scope.resetToolbarOptions = resetToolbarOptions;
  $scope.filterByActivityType = filterByActivityType;
  $scope.isPinInRoute = isPinInRoute;
  $scope.removeStopsFromRoute = removeStopsFromRoute;
  $scope.updateAllottedTime = updateAllottedTime;
  $scope.selectGroupRow = selectGroupRow;

  let filters = {};
  filters = $scope.buildFilters();

  $scope.$onInit();

  function getFilterWithRadius(radiusFilter) {
    return radiusFilter
      ? Object.assign({}, $scope.buildFilters(), {area: {radius: radiusFilter.radius * (doesUseMiles() ? 1609.334 : 1000.0), uom: 'meter', point: radiusFilter.center}})
      : filters;
  }

  async function $onInit() {
    helperService.debug('begin $onInit');
    $scope.attachScopeBindings();

    if (MAN && MAN.nukeMapContent) {
      MAN.nukeMapContent();
    }

    try {
      await MainService.startChainReaction();
      $scope.loadMap();
    } catch (error) {
      helperService.logError(error);
    }

    $scope.$applyAsync(() => {
      $timeout(() => {
        $('#startTimeDiv').datetimepicker({format: 'LT'}).on('dp.change', this.updateStartTime.bind(this));
        $('#startTimeDiv').data('DateTimePicker');
        const startAtElement = $('#start-time').parent();
        if (startAtElement.data('DateTimePicker')) {
          startAtElement.data('DateTimePicker').date(RoutingService.getStartAt());
        }
        startAtElement.on('dp.change', this.updateStartTime.bind(this));

        $('#startDateDiv').datetimepicker({format: 'MMM DD, YYYY'});
        $('#startDateDiv').data('DateTimePicker').minDate(moment().startOf('day'));
        const startDateElement = $('#start-date').parent();
        if (startDateElement.data('DateTimePicker')) {
          startDateElement.data('DateTimePicker').date(RoutingService.getStartAt());
        }
        startDateElement.on('dp.change', this.updateStartDate.bind(this));
      });
    });
    $scope.$digest();
    MODEL.onUpdateRouteTypeSelected = () => {
      $scope.updateUi();
    };
  }

  function attachScopeBindings() {
    $scope.localStorage = safeLocalStorage;

    $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

    // bind data model to scope for access in views
    $scope.data = MODEL;
    $scope.contacts = {};
    $scope.accounts = {};
    $scope.deals = {};
    $scope.group = {
      activityTypeId: '',
    };

    $scope.startingAddress = MODEL.RoutingService.startingAddress;
    $scope.routeTypeSelected = MODEL.RoutingService.routeTypeSelected;
    $scope.endingAddress = MODEL.RoutingService.endingAddress;
    // bind current route id to scope for access in views
    $scope.currentRoute = $route.current.id;

    // outerWidth
    $scope.outerWidth = window.outerWidth;

    $scope.RoutingServiceActions.updateShowRoutesPins(true);
    $scope.initialRouteContacts = {};
    $scope.initialRouteAccounts = {};

    $scope.marketingURL = window.__env.marketingURL;
    $scope.integrationsURL = window.__env.integrationsURL;

    switch (page) {
      case 'accountsMapPage':
        window.location.href = getNewAppUrl('/map');
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.accounts : {};
        $scope.entityType = EntityType.COMPANY;
        break;
      case 'contactsMapPage':
        window.location.href = getNewAppUrl('/map');
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.contacts : {};
        $scope.entityType = EntityType.PERSON;
        break;
      case 'accountsRoutingMapPage':
      case 'contactsRoutingMapPage':
        $scope.RoutingServiceActions.initRouting();
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.routes : {};
        break;
      case 'territoriesMapPage':
        window.location.href = getNewAppUrl('/map');
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.territories : {};
        $scope.entityType = EntityType.COMPANY;
        break;
      case 'individualAccountsRoutePage':
        $scope.entityType = EntityType.COMPANY;
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.routes : {};
        $scope.RoutingServiceActions.updateIsEditingRoute(false);
        break;
      case 'individualContactsRoutePage':
        $scope.entityType = EntityType.PERSON;
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.routes : {};
        $scope.RoutingServiceActions.updateIsEditingRoute(false);
        break;
      case 'routeCreatePage':
        $scope.currentState = MODEL.cachedState ? MODEL.cachedState.routes : {};
        $scope.RoutingServiceActions.updateIsEditingRoute(false);
        break;
      case 'accountsGroupsAccountListPage':
        window.location.href = getNewAppUrl('/map');
        $scope.entityType = EntityType.COMPANY;
        break;
      case 'dealsGroupsDealListPage':
        window.location.href = getNewAppUrl('/map');
        $scope.entityType = EntityType.DEAL;
        break;
      case 'contactsGroupsContactListPage':
        window.location.href = getNewAppUrl('/map');
        $scope.entityType = EntityType.PERSON;
        break;
    }

    MODEL.MappingService.singlePinEntityType = $scope.entityType;

    if (!safeLocalStorage.leadGenSearches) {
      safeLocalStorage.leadGenSearches = [];
    }

    // debug tools
    if (UTILS.env !== 'production' || UTILS.debugMode()) {
      window.$route = $route;
      window.$scope = $scope;
    }

    if (!MODEL.TeamsService.verifiedTeamMembers.length) {
      TeamService.getOrgDetails()
        .then(() => {
          $scope.setLeadsView();
        })
        .catch((error) => {
          console.error(error);
          MODEL.loader.show = false;
        });
    } else {
      $scope.setLeadsView();
    }
  }

  function setLeadsView() {
    $scope.data.currentLeadsView = UTILS.setLeadsView($scope.currentState);
  }

  async function loadGroups(crmObjectType) {
    if (crmObjectType) {
      const response = await addEditNetworkService.getGroups(crmObjectType);
      MODEL.GroupsService.currentPageGroups = response.data;
      MODEL.GroupsService.currentPageGroupsCount = response.total;
      window.refreshDom(
        {
          currentPageGroups: MODEL.GroupsService.currentPageGroups,
          currentPageGroupsCount: MODEL.GroupsService.currentPageGroupsCount,
        },
        'GroupsService',
      );
    }
  }

  function saveMapState(entitiesAreCovered, radiusFilter = false) {
    if (entitiesAreCovered) {
      MODEL.MappingService.coveredMapRadiusFilter = radiusFilter;
    }
    MODEL.MappingService.entitiesAreCovered = entitiesAreCovered;
  }

  async function loadMap() {
    helperService.debug('Begin loadMap');
    MODEL.currentSubPage = 'map';

    // reset find new customers
    MODEL.MappingService.findNewCustomers = true;
    MODEL.MappingService.onChangeMapView = false;
    MODEL.routeObjectsSaved = undefined;
    $scope.showFindNewCustomers(false, true);

    MODEL.show.loader = true;
    let recordCount = 0;
    const currentRouteId = get($route.current, 'id');

    helperService.debug('page', currentRouteId);
    if (currentRouteId === 'accountsMapPage') {
      recordCount = await loadAccountsForMap();
      saveMapState(recordCount < MODEL.recordsUpperLimit);
      MODEL.MappingService.onChangeMapView = debounce(async (radiusFilter) => {
        window.refreshDom({loader: true}, 'show');
        await loadAccountsForMap(radiusFilter);
        saveMapState(MODEL.accounts.length < MODEL.recordsUpperLimit, radiusFilter);
        window.refreshDom({loader: false}, 'show');
      }, 1500);
    } else if (currentRouteId === 'contactsMapPage') {
      recordCount = await loadContactsForMap();
      MODEL.MappingService.onChangeMapView = debounce(async (radiusFilter) => {
        window.refreshDom({loader: true}, 'show');
        await loadContactsForMap(radiusFilter);
        saveMapState(MODEL.contacts.length < MODEL.recordsUpperLimit, radiusFilter);
        window.refreshDom({loader: false}, 'show');
      }, 1500);
    } else if (currentRouteId === 'accountsRoutingMapPage') {
      recordCount = await loadAccountsForRouteMap();
    } else if (currentRouteId === 'contactsRoutingMapPage') {
      recordCount = await loadContactsForRoutesMap();
    } else if (currentRouteId === 'territoriesMapPage') {
      await $scope.fetchTerritories();
      recordCount = MODEL.territoryCount;
    } else if (currentRouteId === 'accountsGroupsMapPage') {
      recordCount = await loadAccountGroupsForMap();
    } else if (currentRouteId === 'contactsGroupsMapPage') {
      recordCount = await loadContactGroupsForMap();
    } else if (currentRouteId === 'dealsGroupsMapPage') {
      recordCount = await loadDealGroupsForMap();
    } else if (window.isOnPage('groups/list')) {
      await loadGroupsForMap();
    } else if (currentRouteId === 'individualContactsRoutePage' || currentRouteId === 'individualAccountsRoutePage') {
      await loadIndividualRouteForMap();
    } else if (currentRouteId === 'routeCreatePage') {
      MainService.viewManipulationOnLoad();
      RoutingService.enableDragDrop(true, $scope.updateUi);
      RoutingService.setIndividualHeaders();
      await RoutingService.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
      RoutingService.hidePinsNotInRoute();
      $scope.RoutingServiceActions.updateIsEditingRoute(true);
      window.refreshDom({loader: false}, 'show');
    }

    // show record limit warning modal if necessary
    MappingService.showRecordLimitWarningModal(recordCount);

    if (shouldFindLeads) {
      $scope.showFindNewCustomers();
    }

    helperService.debug('Complete loadMap');
    window.refreshDom({loader: false}, 'show');
  }

  async function loadAccountsForMap(radiusFilter = false) {
    if (MODEL.accounts.length && MODEL.accounts.length === MODEL.accountsCount) {
      window.refreshDom({loadingMessage: true}, 'show');
    }

    try {
      const recordCount = await $scope.fetchAccounts(radiusFilter);
      window.refreshDom({loadingMessage: false}, 'show');
      return recordCount;
    } catch (error) {
      window.refreshDom({loader: false}, 'show');
      swal('Uh-oh', 'We were unable to retrieve your companies. Please try again', 'error');
    }
  }

  async function loadContactsForMap(radiusFilter = false) {
    const columns = 'id,name,firstName,lastName,geoPoint,color,address,city,country,countryCode,phone,postalCode';
    await Promise.all([
      AllContactsService.fetchContacts(true, getFilterWithRadius(radiusFilter), false, false, false, columns),
      RoutingService.fetchRoutes({}),
      $scope.loadGroups('contacts'),
    ]);
    const recordCount = MODEL.contactsCount;
    AllContactsService.setMapView(true);
    AllContactsService.setViewHeaders(true);
    if (MODEL.MappingService.heatMapEnabled) {
      MappingService.enableHeatMap();
    }
    return recordCount;
  }

  async function loadAccountsForRouteMap() {
    await Promise.all([
      AccountsService.fetchAccounts(true, filters),
      $scope.loadGroups('accounts'),
    ]);
    AccountsService.setMapView();
    const recordCount = MODEL.accountsCount;
    RoutingService.setViewHeaders(true);

    return recordCount;
  }

  async function loadContactsForRoutesMap() {
    await Promise.all([
      AllContactsService.fetchContacts(true, filters),
      $scope.loadGroups('contacts'),
    ]);
    AllContactsService.setMapView();
    const recordCount = MODEL.contactsCount;
    RoutingService.setViewHeaders(true);

    return recordCount;
  }

  async function loadAccountGroupsForMap() {
    await Promise.all([
      AccountsService.fetchAccounts(true, filters),
      $scope.loadGroups('accounts'),
    ]);
    AccountsService.setMapView();
    await GroupsService.fetchGroupsForMap('accounts');
    const recordCount = MODEL.GroupsService.currentPageGroupsCount;
    GroupsService.setViewHeaders('Mapped Companies', MODEL.accountsCount);

    return recordCount;
  }

  async function loadContactGroupsForMap() {
    await Promise.all([
      AllContactsService.fetchContacts(true, filters),
      $scope.loadGroups('contacts'),
    ]);
    AllContactsService.setMapView();
    await GroupsService.fetchGroupsForMap('contacts');
    const recordCount = MODEL.GroupsService.currentPageGroupsCount;
    GroupsService.setViewHeaders('Mapped People', MODEL.contactsCount);

    return recordCount;
  }

  async function loadDealGroupsForMap() {
    await Promise.all([
      FunnelService.fetchDeals(true, filters),
      $scope.loadGroups('deals'),
    ]);
    await GroupsService.fetchGroupsForMap('deals');
    const recordCount = MODEL.GroupsService.currentPageGroupsCount;
    GroupsService.setViewHeaders('Mapped Deals', MODEL.dealsCount);
    // individual crm group list page
    return recordCount;
  }

  async function loadGroupsForMap() {
    const id = $route.current.params.id;

    const type = ['account', 'contact', 'deal'].find(type => window.isOnPage(`${type}s`));
    MODEL.GroupsService.currentGroupsPage = `${type}s`;
    MODEL.GroupsService.currentGroup = await GroupsService.fetchGroup(type, id);

    // get activity types for cadence
    if (window.isOnPage('contacts') || window.isOnPage('accounts')) {
      const response = await CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id);
      $scope.crmActivityTypes = response.data;
    }

    await GroupsService.getAllRecordsForGroup(MODEL.GroupsService.currentGroup);
  }

  async function validateRoute(route, routeStops, entityType) {
    const stopsWithoutCoordinates = routeStops.filter(({geoPoint}) => !geoPoint || !geoPoint.coordinates);
    if (stopsWithoutCoordinates.length) {
      const html = renderInvalidRoutePopupHtml(routeStops, stopsWithoutCoordinates, entityType);
      const onCancel = () => {
        swal.close();
        window.history.back();
      };
      const onRemoveStops = () => {
        swal.close();
        swal({
          title: 'Are you sure?',
          text: 'You won\'t be able to undo this action.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, remove from route',
          showloaderOnConfirm: true,
        })
          .then(() => RoutingService.removeMultipleFromRoute(
            route,
            stopsWithoutCoordinates.map(({id}) => id),
            mmcConst.pluralEntityTypeToSingularEntityType[entityType],
          ))
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            if (err === 'cancel') {
              window.history.back();
              return;
            }
            swal('Uh-Oh', "Sorry, we couldn't remove those stops. Please try again.", 'error');
            window.location.reload();
          });
      };
      const onDeleteRoute = async () => {
        swal.close();
        swal({
          title: 'Are you sure?',
          text: "You won't be able to recover this route.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete',
          closeOnConfirm: false,
        })
          .then(() => routingNetworkService.deleteRoute(route.id))
          .then(() => {
            analyticsService.entityDeleted('Route', route);
            window.history.back();
          })
          .catch((error) => {
            if (error === 'cancel') {
              window.history.back();
              return;
            }
            swal('Uh-Oh', "Sorry, we couldn't remove this route. Please try again.", 'error');
            window.location.reload();
          });
      };

      await swal({
        title: 'Invalid route',
        icon: 'warning',
        html,
        showCloseButton: false,
        showConfirmButton: false,
        showCancelButton: false,
        onOpen: (dialog) => {
          $(dialog).find('.swal2-content .js-remove-stops').on('click', onRemoveStops);
          $(dialog).find('.swal2-content .js-delete-route').on('click', onDeleteRoute);
          $(dialog).find('.swal2-content .js-cancel').on('click', onCancel);
        },
      });
    }
  }

  async function loadIndividualRouteForMap() {
    if (MODEL.RoutingService.fromEditForm) {
      $scope.RoutingServiceActions.updateFromEditForm(false);
      // get all contacts
      await AllContactsService.fetchContacts(true, {});
      AllContactsService.setMapView();
      RoutingService.setIndividualHeaders();
      RoutingService.hidePinsNotInRoute();
      MAN.fitBounds(MODEL.RoutingService.bounds);
      // set marker
      MODEL.RoutingService.routeMarkers.forEach(marker => {
        if (marker) {
          marker.setMap(MODEL.map);
          MAN.allMapContent.push(marker);
        }
      });
    } else {
      // get route details and contacts
      const id = UTILS.getIdFromCurrentUrl();

      MainService.viewManipulationOnLoad();

      const currentRoute = await routingNetworkService.getRouteDetails(id);
      if (!currentRoute.routeDetail.startAt) {
        // if start date is NULL then set current time minus 1 second to trigger time changing below
        currentRoute.routeDetail.startAt = moment().add(-1, 'second').toISOString();
      }

      const entityType = window.isOnPage('accounts') ? 'Accounts' : 'Contacts';
      await validateRoute(currentRoute, currentRoute[`route${entityType}`], entityType.toLowerCase());

      $scope.RoutingServiceActions.updateCurrentRoute(currentRoute);
      $scope.RoutingServiceActions.updateOriginalRoute(currentRoute);
      const entityService = window.isOnPage('accounts') ? AccountsService : AllContactsService;

      $scope.RoutingServiceActions.updateCurrentRouteObjects(MODEL.RoutingService.currentRoute[`route${entityType}`]);

      // get all accounts
      await entityService[`fetch${entityType}`](true, {includeGroups: false});
      const uniqueIds = new Set(MODEL[entityType.toLowerCase()].map(({id}) => id));

      // then append all stops from current route (we need this because if some of route stops are
      // not in top 10k records, they would not be displayed)
      MODEL[entityType.toLowerCase()] = [
        ...MODEL[entityType.toLowerCase()],
        ...MODEL.RoutingService.currentRouteObjects.filter(({id}) => !uniqueIds.has(id)),
      ];
      entityService.setMapView();

      $scope.RoutingServiceActions.updateRouteObjects(MODEL.RoutingService.currentRouteObjects.map((routeObject, index) => {
        $scope[`initialRoute${entityType}`][routeObject.id] = {
          displayOrder: index,
        };

        return {
          ...routeObject,
          latitude: routeObject.geoPoint.coordinates[1],
          longitude: routeObject.geoPoint.coordinates[0],
        };
      }));
      $scope.RoutingServiceActions.updateAddressDetails({
        startingAddress: MODEL.RoutingService.currentRoute.routeDetail.startAddress,
        endingAddress: MODEL.RoutingService.currentRoute.routeDetail.endAddress,
        routeTypeSelected: MODEL.RoutingService.currentRoute.routeDetail.type,
      });
      let startAtToSet = MODEL.RoutingService.currentRoute.routeDetail.startAt;
      let payloadToUpdateRoute;
      const routeId = MODEL.RoutingService.currentRoute.id || '';
      if (moment().isAfter(moment(currentRoute.routeDetail.startAt))) {
        swal({
          title: 'Start Date Updated',
          text: 'This date was updated to the current day because routes can’t be in the past.',
          type: 'success',
        });

        const date = moment().add(1, 'days');
        const oldDate = moment(startAtToSet);
        date.set('hour', oldDate.get('hour'));
        date.set('minute', oldDate.get('minute'));
        startAtToSet = date.toISOString();
        const {routeDetail, name, id} = MODEL.RoutingService.currentRoute;
        payloadToUpdateRoute = {id, name, routeDetail: {...routeDetail, startAt: startAtToSet}};
      }
      $scope.RoutingServiceActions.updateStartDate(startAtToSet);
      $scope.RoutingServiceActions.updateStartTime(startAtToSet);
      const startAtElement = $('#start-time').parent();
      if (startAtElement.data('DateTimePicker')) {
        startAtElement.data('DateTimePicker').date(moment(new Date(startAtToSet)));
      }
      const startDateElement = $('#start-date').parent();
      if (startDateElement.data('DateTimePicker')) {
        startDateElement.data('DateTimePicker').date(moment(new Date(startAtToSet)));
      }
      RoutingService.setIndividualHeaders();

      const route = MODEL.RoutingService.currentRoute;
      const startingLocation = `${route.routeDetail.startGeoPoint.coordinates[1]},${route.routeDetail.startGeoPoint.coordinates[0]}`;
      let endingLocation = '';

      if (route.routeDetail.endGeoPoint && route.routeDetail.endGeoPoint.coordinates) {
        endingLocation = `${route.routeDetail.endGeoPoint.coordinates[1]},${route.routeDetail.endGeoPoint.coordinates[0]}`;
      }

      await RoutingService.showRouteDetails(startingLocation, endingLocation);
      RoutingService.hidePinsNotInRoute();
      if (payloadToUpdateRoute) {
        await routingNetworkService.updateRoute(routeId, payloadToUpdateRoute);
      }
      await userRecentNetworkService.addUserRecent({
        entity: {id: routeId, type: window.isOnPage('accounts') ? 'account-routes' : 'contact-routes'},
      });
    }
    $scope.updateUi();
  }

  async function fetchAccounts(radiusFilter = false) {
    helperService.debug('Begin fetchAccounts');
    const columns = 'id,name,geoPoint,color,address,city,region';
    await Promise.all([
      AccountsService.fetchAccounts(true, getFilterWithRadius(radiusFilter), false, false, false, columns),
      $scope.loadGroups('accounts'),
    ]);
    AccountsService.setMapView(true);
    AccountsService.setViewHeaders(true);
    if (MODEL.MappingService.heatMapEnabled) {
      MappingService.enableHeatMap();
    }
    helperService.debug('accountsCount', MODEL.accountsCount);
    return MODEL.accountsCount;
  }

  // set zoom on territory being edited
  function zoomEditTerr() {
    const index = MODEL.TerritoriesService.currentTerritoryIndex;
    const pins = MODEL.TerritoriesService.selectedUserTerritories[index].points;
    const bounds = new google.maps.LatLngBounds();
    pins.forEach(pin => {
      if (pin[0] && pin[1]) {
        const pinLatlng = new google.maps.LatLng(pin[0], pin[1]);
        bounds.extend(pinLatlng);
      }
    });
    MODEL.map.fitBounds(bounds);
  }

  //
  // -------------------- GENERAL / UTILITY Methods -------------------- //
  //

  // apply filters
  function applyFiltersOnMap() {
    if (MODEL.filteredGroups.length > 0) {
      if (MODEL.MappingService.heatMapEnabled) {
        // disable all the other tools on the map
        MappingService.disableHeatMap();
      }

      MainService.startChainReaction();
      $('#modalbg').hide();
      $('#modal').hide();
    }
  }

  // add this local customer
  async function addLocalCustomer(i) {
    // common hidden UI elements
    let customer = MODEL.localCustomersFound[i];
    // enhance customer info with more details
    try {
      MODEL.show.loader = true;
      customer = {...customer, ...await MappingService.getPlaceDetails(customer.place_id)};
    } catch (e) {
      console.error('Failed to get extra place details', e); // proceed with no extra details
    }
    window.refreshDom({loader: false}, 'show');
    $scope.populateLeadGenAccount(customer);
  }

  async function populateLeadGenAccount(customer) {
    // mark that you came from local search page
    MODEL.cameFromLocalSearch = true;

    const {
      country, region, city, address, postalCode, website, phone,
    } = await MappingService.getAddressFromEntity(customer);

    window.refreshDom({
      'NavBar.showCreateCompanyModal': true,
      'NavBar.findLeadsMode': true,
      'NavBar.referenceCompany': {
        name: customer.name,
        address,
        city,
        region,
        countryCode: country,
        postalCode,
        phone,
        website,
        lat: customer.geometry.location.lat(),
        lng: customer.geometry.location.lng(),
      },
    });
  }

  // load number of contacts in this group
  function numGroupCustomers() {
    return MODEL.GroupsService.pinsInGroup.length;
  }

  // get num pins for specific group
  function getNumGroupPins(groupName) {
    return MappingService.getNumGroupPins(groupName);
  }

  // return number of groups left for user
  function getNumGroupsLeft() {
    return MappingService.getNumGroupsLeftForUser();
  }

  // return number of pins left for user
  function numPinsLeft() {
    return safeLocalStorage.currentUser.pins - MODEL.customers.length + MODEL.othersPins;
  }

  // show tools menu
  function showToolsMenu() {
    if (MODEL.MappingService.showMapTools) {
      $('#toolsMenu').slideDown(190);
      $('#toolsButtonLink').attr('aria-expanded', 'true');
      MODEL.MappingService.showMapTools = false;
    } else {
      $('#toolsMenu').slideUp(190);
      $('#toolsButtonLink').attr('aria-expanded', 'false');
      MODEL.MappingService.showMapTools = true;
    }
  }

  // zoom into user's current location on main map, via button in bottom right.
  // NOTE: there is a very similar activity in $scope.findMe() (but that's used for finding location when adding a new contact)
  function findMeButton() {
    if (!MAN.userPosition) {
      MAN.promptForPosition($scope.findMeButton);
    } else {
      MAN.globalMap.setCenter(MAN.userPosition);
      MAN.globalMap.setZoom(10);
    }
  }

  // unsubscribe from all email communications
  function unsubscribeFromAllEmails() {
    MappingService.unsubscribeFromAllEmails();
  }

  // find new customers from Google Places
  function localCustomerSearch() {
    MappingService.localCustomerSearch();
  }

  // hides search box then scrolls down page to show details of customer
  function hideSearchBoxThenScrollThenShowCustomerInfoAndZoom(pin) {
    $scope.hideBelowMapDivStuff();
    $('#close').click();
    setTimeout(() => $('.main-panel').animate({
      scrollTop: 800,
    }, 400),
    350);
    MainService.selectedCustomer(pin);
  }

  // hides any views appearing below the map
  function hideBelowMapDivStuff() {
    // common hidden UI elements
    if (UTILS.isOnPage('groupsPage')) {
      $('#addPinForm').hide();
      $('#searchResults').hide();
      $('#introPage').hide();
      $('#addCustomerBar').hide();
      $('#importCustomers').hide();
      $('#localCustomerSearch').hide();
      $('#groups').hide();
      MODEL.GroupsService.showGroupsDetailList = false;
      $('#nearby').hide();
      $('#territories').hide();
      $('#listRoutes').hide();
      $('#routeDetailsForm').hide();
      $('#pinDroppedEditing').hide();
    }
  }

  // print route
  function print(divName) {
    // window.print();
    const printContents = document.getElementById(divName).innerHTML;
    const popupWin = window.open('', '_blank', 'width=300,height=300');
    popupWin.document.open();
    popupWin.document.write(`<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">${printContents}</body></html>`);
    popupWin.document.close();
  }

  // selected customer
  function selectedCustomer(pin) {
    MainService.selectedCustomer(pin);
  }

  // sort customers, (alphabetically)
  function sortCustomers(type) {
    MappingService.sortCustomers(type);
  }

  // selected a customer
  function selectedLocalCustomer(customer) {
    const latlng = new google.maps.LatLng(customer.geometry.location.lat(), customer.geometry.location.lng());
    MAN.panTo(latlng, !!'force');
    MAN.setZoom(16);
    $('.main-panel').animate({
      scrollTop: 0,
    }, 400);
  }

  // perform search from multiple contacts list
  function searchFromMultipleContactsList() {
    window.location.href = `#/?q=${MODEL.selectedPinObject.latitude},${MODEL.selectedPinObject.longitude}`;
  }

  // remove all popovers
  function closePopOver(multiPin) {
    MappingService.closePopOver(multiPin);
  }

  // scrolls down when clicking pin pop-up
  function popUpClicked() {
    if (isOnRoutesPage() && MODEL.MappingService.creatingOrEditingRoute) {
      return;
    }
    MODEL.currentMapState = {};
    const record = {id: MODEL.MappingService.currentPopOverData.args.marker_id[0]};
    MappingService.cacheMapState(record);
    window.selectedRowCustomerTable(record);
  }

  // changed comparator on filter modal
  function changedComparator() {
    CustomerService.changedComparator($scope.queryRow1Compare, $scope.queryRow2Compare, $scope.queryRow3Compare, $scope.queryRow4Compare);
  }

  // save new pin in find-new-customers page
  function saveNewFoundCustomer() {
    CustomerService.saveCustomer();
    MappingService.saveNewFoundCustomer();
  }

  // save new customer
  function saveCustomer(objectType, obj) {
    if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Company') >= 0) {
      objectType = 'accounts';
      obj = $scope.accounts;
    } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Person') >= 0) {
      objectType = 'contacts';
      obj = $scope.contacts;
    } else {
      objectType = 'deals';
      obj = $scope.deal;
    }

    CustomerService.saveCustomer(objectType, obj);
  }

  //
  // -------------------- CUSTOM FIELDS Methods -------------------- //
  //

  // edit custom field chosen
  function editCustomField(columnNumber) {
    // show edit name boxes
    $('#customFieldBox').hide();
    $('#renameCustomFieldBox').show();
    MODEL.MappingService.currentColumn = columnNumber;
    const invertedObject = invert(safeLocalStorage.currentUser.customFieldsToColumns);
    document.getElementById('newFieldNameEdit').value = invertedObject[columnNumber];
  }

  // save new custom field name
  function saveNewCustomFieldName() {
    $('#renameCustomFieldBox').hide();
    $('#customFieldBox').show();
    $('#filterBox').hide();
    $('#openFilterBox').hide();
    MappingService.saveNewCustomFieldName();
  }

  // deletes custom field chosen
  function deleteCustomField(fieldName) {
    MappingService.deleteCustomField(fieldName);
  }

  //
  // -------------------- TOOLS MENU Methods -------------------- //
  //

  // totally cancel a lasso
  function cancelLassoCompletely() {
    $('#lassoAddToRouteButton').hide();
    $('#lassoSaveButton').hide();
    $('#lassoDeleteButton').hide();
    $('#cancelLassoTool').hide();
    LassoService.cancelLassoCompletely();
  }

  // show modal for groups selection to save lassoed pins into
  async function chooseGroupsFromLasso() {
    if ($route.current.id === 'contactsPage' || $route.current.id === 'contactsMapPage') {
      MODEL.show.loader = true;
      await RoutingService.fetchRoutes({includeContacts: true}, 1, 'updatedAt', false);
      window.refreshDom({loader: false}, 'show');
    }

    $scope.toggleBulkEdit(true);
  }

  function toggleBulkEdit(show) {
    analyticsService.clicked(['List', 'Toggle bulk edit'], {show});
    $scope.showBulkEdit = show;
  }

  // save lassoed pins (instantly)
  function saveLassoedPins() {
    LassoService.saveLassoedPins();
  }

  //
  // ------------------- IMPORTING Methods ------------------- //
  //

  // show check sheet modal
  function goToCheckSheet() {
    $('#uploadImportBox').hide();
    $('#standardImportCheckSheet').hide();
    $('#onboardImportCheckSheet').show();
    $('#checkSheetBox').show();
  }

  // onboard modal to import spreadsheet
  function onboardImportSheet() {
    $('#uploadStyleBox').hide();
    $('#uploadImportBox').hide();
    $('#modal').hide();
    ImportService.importCsvFlow();
  }

  // onboard modal with import tips
  function onboardImportTips() {
    $('#uploadDataBox').hide();
    $('#uploadStyleBox').hide();
    $('#uploadImportBox').show();
  }

  //
  // -------------------- ROUTING Methods -------------------- //
  //

  function routingGoBack() {
    const clearData = (listOfIds) => {
      MODEL.MappingService.popovers.forEach((popover) => {
        popover.remove();
      });
      MODEL.MappingService.pinData = [];
      MODEL.MappingService.popovers = [];
      window.refreshDom({'MappingService.pinData': MODEL.MappingService.pinData});
      window.instantlyTogglePinsFromMap(listOfIds, false);
      MODEL.MappingService.creatingOrEditingRoute = false;
    };
    if (MODEL.RoutingService.isEditingRoute) {
      swal({
        title: 'Are you sure?',
        text: 'You have not saved the changes you made to the route. Are you sure you want to leave this page?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Continue',
        showloaderOnConfirm: true,
      })
        .then(() => {
          if (window.isOnPage('accounts')) {
            clearData(MODEL.accounts.map(({id}) => id));
            window.location.href = '/#/accounts/routing/list';
          } else {
            clearData(MODEL.contacts.map(({id}) => id));
            window.location.href = '/#/contacts/routing/list';
          }
        })
        .catch(() => {});
    } else if (window.isOnPage('accounts')) {
      clearData(MODEL.accounts.map(({id}) => id));
      window.location.href = '/#/accounts/routing/list';
    } else {
      clearData(MODEL.contacts.map(({id}) => id));
      window.location.href = '/#/contacts/routing/list';
    }
  }

  function updateUi() {
    $scope.startingAddress = MODEL.RoutingService.startingAddress;
    $scope.routeTypeSelected = MODEL.RoutingService.routeTypeSelected;
    $scope.endingAddress = MODEL.RoutingService.endingAddress;
  }

  async function detailsChanged(type) {
    $scope.updateUi();
    $scope.RoutingServiceActions.updateEditFlag({
      details: true,
      rebuildRoute: true,
    });
    // rebuild route if order changed
    if (type === 'routeType') {
      await $scope.rebuildRoute();
    }
  }

  function updateAddressDetails() {
    $scope.RoutingServiceActions.updateAddressDetails({
      endingAddress: $scope.endingAddress,
      startingAddress: $scope.startingAddress,
      routeTypeSelected: $scope.routeTypeSelected,
    });
    $scope.detailsChanged();
  }

  function updateStartTime(e) {
    if (!e.date) {
      return;
    }

    $scope.detailsChanged();

    this.RoutingServiceActions.updateStartTime(e.date.toISOString());
    $scope.RoutingServiceActions.updateEditFlag({rebuildRoute: true});

    if (RoutingService.getStartAt().isBefore(new Date())) {
      const startDateElement = $('#start-time').parent();
      if (startDateElement.data('DateTimePicker')) {
        startDateElement.data('DateTimePicker').date(moment().add(5, 'minutes'));
        this.RoutingServiceActions.updateStartTime(moment().add(5, 'minutes').toISOString());
      }
    }

    $scope.$apply();
  }

  function updateStartDate(e) {
    if (!e.date) {
      return;
    }

    $scope.detailsChanged();

    this.RoutingServiceActions.updateStartDate(e.date.toISOString());
    $scope.RoutingServiceActions.updateEditFlag({rebuildRoute: true});

    if (RoutingService.getStartAt().isBefore(new Date())) {
      const startDateElement = $('#start-time').parent();
      if (startDateElement.data('DateTimePicker')) {
        startDateElement.data('DateTimePicker').date(moment().add(5, 'minutes'));
        this.RoutingServiceActions.updateStartTime(moment().add(5, 'minutes').toISOString());
      }
    }

    $scope.$apply();
  }


  function addMarkersToRoute(ids) {
    return RoutingService.addMarkersToRoute(ids);
  }

  function removeMarkersFromRoute(ids) {
    RoutingService.removeMarkersFromRoute(ids);
  }

  function saveNewRoute() {
    RoutingService.saveRoute();
  }

  function openSmartNameModal() {
    RoutingService.openSmartNameModal();
  }

  // route editing functions

  // edit start location
  function editStartLocation() {
    RoutingService.editStartLocation();
  }

  // edit ending location
  function editEndLocation() {
    RoutingService.editEndLocation();
  }

  // edit route type
  function editRouteType() {
    RoutingService.editRouteType();
  }

  function routeStyleChoose() {
    RoutingService.routeStyleChoose();
  }

  function finishRouteCreation() {
    RoutingService.finishRouteCreation();
  }

  async function getCurrentLocation(locationType) {
    await RoutingService.getCurrentLocation(locationType);

    if (locationType === 'startingLocation') {
      $scope.startingAddress = MODEL.RoutingService.startingAddress;
    } else if (locationType === 'endingLocation') {
      $scope.endingAddress = MODEL.RoutingService.endingAddress;
    }
  }

  function clearRouteMarkers() {
    RoutingService.clearRouteMarkers();
  }

  function showCadenceModal() {
    RoutingService.showCadenceModal();
  }

  function showAreaModal() {
    RoutingService.showAreaModal();
  }

  function showAddToRouteModal() {
    $scope.modalsActions.showModal('addToRoute');
  }

  function showSmartRouteGroupModal() {
    RoutingService.showSmartRouteGroupModal();
  }

  // choose route starting point && set route type (in-order or optimized)
  function chooseRouteStartingPoint(optimize) {
    // show route style options modal
    $('#modalbg').show();
    $('#modal').show();
    $('#routeTimePerStop').show();
    $('#routeStyleBox').hide();
    $('#routeStartingPoint').hide();
    $('#routeStartingPointTextBox').hide();
    $('#checkSheetBox').hide();
    $('#selectColumnsBox').hide();

    // scroll to top if mobile
    if (UTILS.isMobile.any()) {
      $('.main-panel').animate({
        scrollTop: 0,
      }, 0);
    }

    $scope.RoutingServiceActions.selectOptimize(optimize);
  }

  // choose how much time to spend at each stop along the route
  function chooseRouteTimePerStop() {
    // show route style options modal
    $('#modalbg').show();
    $('#modal').show();
    $('#routeStartingPoint').show();
    $('#routeStartingPointTextBox').hide();
    $('#routeTimePerStop').hide();
    $('#routeStyleBox').hide();
    $('#checkSheetBox').hide();
    $('#selectColumnsBox').hide();

    // scroll to top if mobile
    if (UTILS.isMobile.any()) {
      $('.main-panel').animate({
        scrollTop: 0,
      }, 0);
    }
  }

  // sort routes from UI
  function sortRoutes(colName) {
    MainService.highlightSelectedTableHeader(['name', 'stops'], colName);
    RoutingService.sortRoutes();
  }

  // cancel current route being made
  function cancelRoute() {
    $scope.showTeamHeader = false;
    analyticsService.canceled('Route creation');
    // Hard reload the page to drop route state.
    window.location.reload();
    // RoutingService.cancelRoute();
  }

  // close route
  function closeRoute() {
    RoutingService.closeRoute();
    window.refreshDom({loader: true}, 'show');
    FetchCRMDataService.fetchCRMData()
      .then(() => {
        window.refreshDom({loader: false}, 'show');
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  }

  /* Routing modals' close functions */
  function closeSmartWelcomeModal() {
    $scope.$evalAsync(() => {
      $scope.RoutingServiceActions.closeSmartWelcomeModal();
    });
  }

  function closeSmartAreaModal() {
    $scope.$evalAsync(() => {
      $scope.RoutingServiceActions.closeSmartAreaModal();
    });
  }

  // show/hide pins already in routes
  function toggleShowHideRoutesPin() {
    $scope.RoutingServiceActions.updateShowRoutesPins(!MODEL.RoutingService.showRoutesPins);
    const text = MODEL.RoutingService.showRoutesPins ? ' Hide Pins In Route' : ' Show Pins In Route';
    analyticsService.clicked(['Routes', 'Map', MODEL.RoutingService.showRoutesPins ? 'Show Pins In Route' : 'Hide Pins In Route']);
    $('#hideShowRoutesListItem b').text(text);
    RoutingService.populatePinsAlreadyInRoutes();
  }

  function handlePinClicksForRouting(contactId, multiplePin = false) {
    MappingService.handlePinClicksForRouting(contactId, multiplePin);
  }

  async function rebuildRoute() {
    await RoutingService.rebuildRoute();

    $scope.RoutingServiceActions.updateEditFlag({rebuildRoute: false});
    window.refreshDom({loader: false}, 'show');
  }

  // show edit route modal
  function editRoute(route) {
    MODEL.show.loader = true;
    route = route || MODEL.RoutingService.currentRoute;

    RoutingService.enableDragDrop(true, $scope.updateUi);
    $('#saveRouteButton').show();
    $('#cancelRouteButton').show();

    // show lasso route tool
    $('#lassoRouteListItem').show();
    $scope.RoutingServiceActions.editRoute(route);
    $scope.RoutingServiceActions.updateEditFlag({rebuildRoute: true});

    const entityType = window.isOnPage('accounts') ? 'Accounts' : 'Contacts';
    MODEL.RoutingService.currentRouteObjects.forEach((routeObject, index) => {
      $scope[`initialRoute${entityType}`][routeObject] = {
        displayOrder: index,
      };
    });

    MODEL.MappingService.creatingOrEditingRoute = true;
    // MODEL.MappingService.newRoute = MODEL.RoutingService.currentRouteViewing.points;
    MODEL.MappingService.creatingOrEditingRoute = true;
    // reset all the creating/editing variables
    MODEL.creatingRoute = true;
    let object = [];
    if (window.isOnPage('accounts')) {
      object = MODEL.accounts;
    } else {
      object = MODEL.contacts;
    }

    const pinsNotInRoute = object.reduce((vals, c) => {
      if (MODEL.RoutingService.currentRouteObjects.indexOf(c.id) === -1) {
        vals.push(c.id);
      }
      return vals;
    }, []);

    window.instantlyTogglePinsFromMap(pinsNotInRoute, true);

    $scope.RoutingServiceActions.updateOriginalRoute(route);
    MODEL.show.loader = false;
  }

  // submit route
  function submitRoute(routeName) {
    $scope.showTeamHeader = true;
    RoutingService.submitRoute(routeName);
  }

  function detailsPayload() {
    const {id, name} = MODEL.RoutingService.currentRoute;
    const allottedTime = parseInt(MODEL.RoutingService.timePerRouteStop, 10);

    const startAt = RoutingService.getStartAt().toISOString();

    $scope.RoutingServiceActions.updateCurrentRoute({
      id,
      name,
      routeDetail: {
        allottedTime,
        startAt,
        startAddress: MODEL.RoutingService.startingAddress,
        endAddress: MODEL.RoutingService.endingAddress || null,
        type: MODEL.RoutingService.routeTypeSelected,
        startGeoPoint: {
          type: 'Point',
          coordinates: MODEL.RoutingService.startingLocationCoords,
        },
        endGeoPoint: MODEL.RoutingService.endingAddress ? {
          type: 'Point',
          coordinates: MODEL.RoutingService.endingLocationCoords,
        } : null,
      },
    });
    $scope.RoutingServiceActions.updateOriginalRoute(MODEL.RoutingService.currentRoute);
  }

  function contactsPayload() {
    const payload = {
      routeContactGroups: [],
    };
    MODEL.RoutingService.currentRouteObjects.forEach((contact) => {
      // check for same contacts in the initial list
      const contactId = parseInt(contact, 10);
      const index = MODEL.routeObjectsSaved.findIndex((row) => row.id === contactId);

      if ($scope.initialRouteContacts[contact]) {
        payload.routeContactGroups.push({
          contactId,
          displayOrder: index,
          _action_: 'update',
          allottedTime: Object.keys(this.allottedTime ?? {}).includes(contactId.toString())
            ? this.allottedTime[contactId] * 60
            : MODEL.RoutingService.currentRoute.routeDetail.allottedTime,
        });
        // clearing list
        delete $scope.initialRouteContacts[contact];
      } else {
        // if not present in the list, this is a new contact

        payload.routeContactGroups.push({
          contactId: parseInt(contact, 10),
          displayOrder: index,
          _action_: 'create',
          allottedTime: Object.keys(this.allottedTime ?? {}).includes(contactId.toString())
            ? this.allottedTime[contactId] * 60
            : MODEL.RoutingService.currentRoute.routeDetail.allottedTime,
        });
      }
    });
    // if still initial list has some contacts, then these need to be deleted
    Object.keys($scope.initialRouteContacts).forEach((contact) => {
      const index = $scope.initialRouteContacts[contact].displayOrder;
      payload.routeContactGroups.push({
        contactId: parseInt(contact, 10),
        displayOrder: index,
        _action_: 'delete',
      });
    });
    return payload;
  }

  function accountsPayload() {
    const payload = {
      routeAccountGroups: [],
    };
    // routeDetail: {allottedTime: 900}
    MODEL.RoutingService.currentRouteObjects.forEach((account) => {
      const accountId = parseInt(account, 10);
      const index = MODEL.routeObjectsSaved.findIndex((row) => row.id === accountId);

      // check for same accounts in the initial list
      if ($scope.initialRouteAccounts[account]) {
        payload.routeAccountGroups.push({
          accountId,
          displayOrder: index,
          _action_: 'update',
          allottedTime: Object.keys(this.allottedTime ?? {}).includes(accountId.toString())
            ? this.allottedTime[accountId] * 60
            : MODEL.RoutingService.currentRoute.routeDetail.allottedTime,
        });
        // clearing list
        delete $scope.initialRouteAccounts[account];
      } else {
        // if not present in the list, this is a new account
        payload.routeAccountGroups.push({
          accountId: parseInt(account, 10),
          displayOrder: index,
          _action_: 'create',
          allottedTime: Object.keys(this.allottedTime ?? {}).includes(accountId.toString())
            ? this.allottedTime[accountId] * 60
            : MODEL.RoutingService.currentRoute.routeDetail.allottedTime,
        });
      }
    });
    // if still initial list has some accounts, then these need to be deleted
    Object.keys($scope.initialRouteAccounts).forEach((account) => {
      const index = $scope.initialRouteAccounts[account].displayOrder;
      payload.routeAccountGroups.push({
        accountId: parseInt(account, 10),
        displayOrder: index,
        _action_: 'delete',
      });
    });
    return payload;
  }

  async function saveRoute() {
    let routeId = MODEL.RoutingService.currentRoute.id || '';
    let payload = {};
    if (MODEL.RoutingService.smartRouting || MODEL.RoutingService.creatingRoute) {
      $scope.RoutingServiceActions.updateCreatingRoute(false);
      $scope.detailsPayload();
      let {...payload} = MODEL.RoutingService.currentRoute;
      MODEL.show.loader = true;
      const response = await routingNetworkService.createRoute(payload);
      window.refreshDom({loader: false}, 'show');
      analyticsService.entityAdded('Route', response);
      if (!response.id) {
        UTILS.showValidationError(response);
        return;
      }

      $scope.RoutingServiceActions.updateCurrentRoute(response);
      $scope.RoutingServiceActions.updateOriginalRoute(response);
      routeId = response.id;
      let route = '';
      if (window.isOnPage('accounts')) {
        payload = $scope.accountsPayload();
        route = 'accounts';
      } else {
        payload = $scope.contactsPayload();
        route = 'contacts';
      }
      // saving route contacts
      await routingNetworkService.updateRouteObjects(routeId, payload);
      const routeToUpdate = {...MODEL.RoutingService.currentRoute};
      routeToUpdate.stopTime = payload.routeAccountGroups.map((row) => ({
        allottedTime: row.allottedTime,
        ...(window.isOnPage('accounts') ? {accountId: row.accountId} : {contactId: row.contactId}),
      }));
      $scope.RoutingServiceActions.updateCurrentRoute(routeToUpdate);
      $scope.RoutingServiceActions.updateIsEditingRoute(false);
      $scope.RoutingServiceActions.cancelSmartRouting();
      window.refreshDom({});
      RoutingService.enableDragDrop();
      window.location.href = `/#/${route}/routing/edit/${routeId}`;
    } else if (MODEL.RoutingService.editFlag.details) {
      // saving route details
      $scope.detailsPayload();
      $scope.RoutingServiceActions.updateCurrentRouteId(routeId);
      const {...payload} = MODEL.RoutingService.currentRoute;
      MODEL.show.loader = true;
      const result = await routingNetworkService.updateRoute(routeId, payload);
      analyticsService.entityUpdated('Route', result);
      window.refreshDom({loader: false}, 'show');
      if (result.validationErrors) {
        UTILS.showValidationError(result);
        return;
      }
      RoutingService.hidePinsNotInRoute();
      RoutingService.enableDragDrop();
      window.refreshDom({loader: false}, 'show');
    }

    if (window.isOnPage('accounts')) {
      MODEL.show.loader = true;
      payload = $scope.accountsPayload();
      await routingNetworkService.updateRouteObjects(routeId, payload);
      const route = {...MODEL.RoutingService.currentRoute};
      route.stopTime = payload.routeAccountGroups.map((row) => ({
        accountId: row.accountId,
        allottedTime: row.allottedTime,
      }));
      $scope.RoutingServiceActions.updateCurrentRoute(route);
      RoutingService.hidePinsNotInRoute();
      RoutingService.enableDragDrop();
      window.refreshDom({loader: false}, 'show');
    } else {
      MODEL.show.loader = true;
      payload = $scope.contactsPayload();
      await routingNetworkService.updateRouteObjects(routeId, payload);
      const route = {...MODEL.RoutingService.currentRoute};
      route.stopTime = payload.routeContactGroups.map((row) => ({
        contactId: row.contactId,
        allottedTime: row.allottedTime,
      }));
      $scope.RoutingServiceActions.updateCurrentRoute(route);
      RoutingService.hidePinsNotInRoute();
      RoutingService.enableDragDrop();
      window.refreshDom({loader: false}, 'show');
    }

    $scope.RoutingServiceActions.updateIsEditingRoute(false);
    MODEL.MappingService.creatingOrEditingRoute = false;
    MODEL.creatingRoute = false;
  }

  // add or remove pin from the existing route
  function togglePinFromRoute(pin) {
    RoutingService.togglePinFromRoute(pin);
  }

  //
  // -------------------- GROUPS / FILTERING Methods -------------------- //
  //

  // remove all of the filters applied to the map
  function resetAllFilters() {
    MODEL.filteredGroups = [];
    MODEL.filterString = '';
    MODEL.searchString = '';
    $('#modal').hide();
    $('#modalbg').hide();
    $('#modalbg').hide();
    $('#greenDotAll').hide();
    $('#greenDotAny').hide();
    $('#greenDotMatching').hide();
    $scope.clearQuery();
    MainService.startChainReaction();
  }

  // created new territory
  // have this weird name since saveTerritory is already being used
  // and cannot go and remove all the dependency on that as of now
  // TODO: To change this back to the normal name
  function saveTerritoryOnTerritoriesPage() {
    if (MODEL.TerritoriesService.isEditingTerr) {
      TerritoriesService.saveEditedTerritory();
    } else {
      TerritoriesService.createNewTerritory();
    }
  }

  // save group color
  function saveGroupColor() {
    $('#setGroupColorBox').hide();
    $('#modalbg').hide();
    $('#modal').hide();

    MappingService.saveGroupColor();
  }

  // filter functions
  function toggleGroupsForFilter(group) {
    if (MODEL.currentlyActiveFilter !== MODEL.filterString) {
      MODEL.filteredGroups = [];
      MODEL.currentlyActiveFilter = MODEL.filterString;
    }

    const indexOfGroup = MODEL.filteredGroups.indexOf(group);
    if (indexOfGroup === -1) {
      MODEL.filteredGroups.push(group);
    } else {
      MODEL.filteredGroups.splice(indexOfGroup, 1);
    }
  }

  // open groups list
  function openGroupsList(filterStringName) {
    $('#modalbg').show();
    $('#modal').show();
    $('#filterBox').hide();
    $('#searchBox').hide();
    $('#openFilterBox').show();
    $('#modal').removeClass('modal-map').removeClass('modal-account').removeClass('modal-filter')
      .addClass('modal-inner-filter');
    if (MODEL.currentlyActiveFilter === '') {
      MODEL.currentlyActiveFilter = filterStringName;
    }
    MODEL.filterString = filterStringName;
  }

  //
  // -------------------- NEARBY Methods -------------------- //
  //

  // refreshes nearby page when user hits enter in search radius field
  function refreshNearbyResults(refresh) {
    // show loading spinner
    MODEL.show.loader = true;
    MainService.fetchData(refresh);
  }

  // set the map tools after ng-include has been loaded
  function mapToolsFinishLoading() {
    MainService.setMapTools();
  }

  // ************************************Find new customers page**************************************/

  // show find new customers
  function showFindNewCustomers(showLoader = true, hideLeadGen) {
    analyticsService.initiated('Lead Gen');
    MappingService.resetNearbyMap();

    if (hideLeadGen) {
      MODEL.MappingService.findNewCustomers = false;
    } else {
      MODEL.MappingService.findNewCustomers = !MODEL.MappingService.findNewCustomers;
    }

    MODEL.localCustomersFound = [];
    MODEL.MappingService.currentSearchTerms = [];
    MODEL.MappingService.currentSearchTermColor = [];

    // reset multiple popu data
    MappingService.resetMultiplePopupData();

    // reset previous tool option if set any
    $scope.resetToolbarOptions('findNewCustomers');

    if (MODEL.MappingService.findNewCustomers) {
      $('#subheader').hide();
      $('#mapViewByUser').hide();
      MAN.nukeMapContent();
      MappingService.addEventListenerToSearchPin();

      // circle to show search radius
      MappingService.setSearchPinLocation(true);
    } else {
      $scope.resetFindNewCustomers(true, showLoader);
    }
  }

  function getSearchCategories() {
    const searchTerm = $('#searchTerm').val().filter(
      newTerm => !MODEL.MappingService.currentSearchTerms.find(currTerm => newTerm === currTerm),
    );
    // MappingService.getSearchCategories($("#searchTerm").val());
    MappingService.getSearchCategories(searchTerm);
  }

  // reset find new customers
  async function resetFindNewCustomers(showPins, showLoader = true) {
    $('#mapViewByUser').show();
    $('#subheader').show();
    if (showLoader) {
      MODEL.show.loader = true;
    }
    if (MODEL.findCustomersCircle) {
      MODEL.findCustomersCircle.setMap(null);
      MODEL.findCustomersCircle = null;
    }

    // new records saved -> get data from the server
    if (MODEL.leadGenMultiRecordsSaved) {
      MAN.nukeMapContent();
      CacheService.checkForFilters();
      // check if already applied filters present
      if ($route.current.id === 'accountsMapPage') {
        await AccountsService.fetchAccounts(true, filters);
        AccountsService.setMapView();
        AccountsService.setViewHeaders(true);
      } else if ($route.current.id === 'contactsMapPage') {
        await AllContactsService.fetchContacts(true, filters);
        AllContactsService.setMapView();
        AllContactsService.setViewHeaders(true);
      } else if ($route.current.id === 'accountsRoutingMapPage') {
        $scope.RoutingServiceActions.updateIsEditingRoute(false);
        await AccountsService.fetchAccounts(true, filters);
        AccountsService.setMapView();
        RoutingService.setViewHeaders(true);
      }
    } else if ($route.current.id === 'accountsMapPage') {
      AccountsService.setMapView();
      AccountsService.setViewHeaders(true);
    } else if ($route.current.id === 'contactsMapPage') {
      AllContactsService.setMapView();
      AllContactsService.setViewHeaders(true);
    } else if ($route.current.id === 'accountsRoutingMapPage') {
      AccountsService.setMapView();
      RoutingService.setViewHeaders(true);
    } else if ($route.current.id === 'contactsRoutingMapPage') {
      AllContactsService.setMapView();
      RoutingService.setViewHeaders(true);
    }

    if (showLoader) {
      window.refreshDom({loader: false}, 'show');
    }

    $('#leadGenMultipleSelectButton').hide();
    MODEL.MappingService.showLeadGenMultipleSelect = false;
    $('#leadGenMultipleSelectButton b').html('Select Multiple');
    $('#leadGenMultipleSelectButton').parent().removeClass('lasso-tools--leadgen-close');
    MODEL.MappingService.showLeadGenMultipleSelect = false;
    MODEL.leadGenMultiRecordsSaved = false;
  }

  // show select multiple button
  function leadGenSelectMultiple() {
    analyticsService.clicked(['Lead Gen', 'Select multiply']);
    MODEL.MappingService.showLeadGenMultipleSelect = !MODEL.MappingService.showLeadGenMultipleSelect;
    MODEL.MappingService.multiSelectPage = $route.current.id.includes('account') ? 'accounts' : 'contacts';

    if (MODEL.MappingService.showLeadGenMultipleSelect) {
      $('#leadGenMultipleSelectButton b').html('Done');
      $('#leadGenMultipleSelectButton').parent().addClass('lasso-tools--leadgen-close');
    } else {
      $('#leadGenMultipleSelectButton b').html('Select Multiple');
      $('#leadGenMultipleSelectButton').parent().removeClass('lasso-tools--leadgen-close');
      // unselect all selected and return their normal icon
      const records = MODEL.selectedLeadGenRecords;
      MODEL.selectedLeadGenRecords = [];
      records.forEach(record => {
        record.marker.setIcon({url: MappingService.getMarkerIconUrl(MappingService.getMarkerColor(record))});
      });
    }
  }

  function saveLeadGenMultipleRecords() {
    LeadGenService.saveLeadGenMultipleRecords();
  }

  // select multiple lead gen records
  function selectLeadGenRecords() {
    LeadGenService.selectLeadGenRecords();
  }

  // save new lead gen searches
  function saveNewSearches() {
    LeadGenService.saveNewSearches();
  }

  // reset Toolbar Options
  // reset everythin other than selected
  function resetToolbarOptions(selectedOption) {
    if (selectedOption !== 'lasso') {
      $scope.cancelLassoCompletely();
    }

    if (selectedOption !== 'findNewCustomers' && $route.current.id === 'accountsMapPage') {
      if (MODEL.MappingService.findNewCustomers) {
        MODEL.MappingService.findNewCustomers = !MODEL.MappingService.findNewCustomers;

        MODEL.localCustomersFound = [];
        MODEL.MappingService.currentSearchTerms = [];
        MODEL.MappingService.currentSearchTermColor = [];
        $scope.resetFindNewCustomers(false);
      }
    }

    if (selectedOption !== 'heatmap') {
      MappingService.disableHeatMap(false);
    }
  }

  // close the form that was tapped
  function closeAccount(objectType) {
    $scope.accounts = {};
    $scope.contacts = {};
    MainService.closeAccount(objectType);
  }

  function unhighlightMapPins() {
    RoutingService.unhighlightMapPins(MODEL.RoutingService.currentRouteObjects);
    $scope.RoutingServiceActions.resetObjects();
    $scope.cancelLassoCompletely();
  }

  /** ************************** Terrs page ********************************** */
  async function fetchTerritories() {
    window.refreshDom({loader: true}, 'show');

    const filters = $scope.buildFilters();
    if (!MODEL.TerritoriesService.showOtherPins) {
      filters.territoryIds = MODEL.territories
        .filter(({territoryDetail}) => !territoryDetail.hidden)
        .map(({id}) => id);
    }
    const [, {territories, territoryCount}] = await Promise.all([
      AccountsService.fetchAccounts(true, filters),
      TerritoriesService.fetchTerritories(
        {},
        0,
        MODEL.recordsUpperLimit,
        undefined,
        undefined,
        {mapped: true},
      ),
    ]);
    await window.refreshDom({territories, territoryCount});
    TerritoriesService.setMapView();
    TerritoriesService.setViewHeaders(true);

    window.refreshDom({loader: false}, 'show');
  }

  async function saveTerritory() {
    const
      id = MODEL.TerritoriesService.currentTerrId;
    const name = MODEL.TerritoriesService.territoryName.trim();
    MODEL.TerritoriesService.territoryName = name;

    if (!TerritoriesService.validateTerritoryName(name)) {
      return;
    }

    if (id) {
      await TerritoriesService.saveTerritoryName(id, name);
      $scope.closeAddEditTerritory();
      $scope.fetchTerritories();
      return;
    }

    $scope.closeAddEditTerritory();
    TerritoriesService.createTerritoryBounds();
  }

  function closeAddEditTerritory() {
    analyticsService.canceled('Territory Creation');
    window.refreshDom({addEditPopupMode: undefined}, 'TerritoriesService');
  }

  function clearTerritoryMarkers() {
    analyticsService.clicked(['Territory', 'Markers', 'Clear']);
    TerritoriesService.clearTerritory();
  }

  function cancelTerritoryBounds() {
    TerritoriesService.cancelTerritory();
  }

  async function saveTerritoryBounds() {
    await TerritoriesService.createTerritory();
    $scope.fetchTerritories();
  }

  function territoryNameInputChanged() {
    MODEL.TerritoriesService.territoryName =
            MODEL.TerritoriesService.territoryName.replace(/^[^\w\s-–]/gi, '');
  }

  function closeEditZipsPopup() {
    window.refreshDom(
      {showEditZipsPopup: false, currentTerrId: undefined, territoryZips: ''},
      'TerritoriesService',
    );
  }

  async function saveTerritoryZips() {
    const
      id = MODEL.TerritoriesService.currentTerrId;
    const zips = MODEL.TerritoriesService.territoryZips.trim();
    MODEL.TerritoriesService.territoryZips = zips;

    if (!TerritoriesService.validateTerritoryZips(zips)) {
      return;
    }

    if (id) {
      await TerritoriesService.updateTerritoryZips(id, zips);
    } else {
      const name = MODEL.TerritoriesService.territoryName;
      await TerritoriesService.createTerritoryWithZips(name, zips);
    }
    $scope.closeEditZipsPopup();
    $scope.fetchTerritories();
  }

  // populates the add new customer form with your current location
  async function findMe() {
    const crmObject = window.isOnPage('contacts') ? $scope.contacts : $scope.accounts;
    await MainService.findMe(crmObject);
  }

  // shows red and white cadence colors for all pins
  function toggleCadenceColors() {
    MODEL.show.loader = true;
    $scope.group.activityTypeId = '';
    MODEL.GroupsService.showCadenceLegend = !MODEL.GroupsService.showCadenceLegend;
    analyticsService.clicked(['Group', 'Show cadence colors'], {show: MODEL.GroupsService.showCadenceLegend});
    MODEL.GroupsService.showActivityTypeFilter = !MODEL.GroupsService.showActivityTypeFilter;
    GroupsService.getAllRecordsForGroup(MODEL.GroupsService.currentGroup, MODEL.GroupsService.showCadenceLegend);
  }

  function selectGroupRow(row) {
    MODEL.MappingService.singlePinData = row;
    window.refreshDom({'MappingService.singlePinData': MODEL.MappingService.singlePinData});
  }

  function filterByActivityType(activityId) {
    MODEL.show.loader = true;
    GroupsService.getAllRecordsForGroup(MODEL.GroupsService.currentGroup, MODEL.GroupsService.showCadenceLegend, activityId);
  }

  // close the group detail view
  function closeGroupDetail() {
    window.location.href = window.location.href.replace(/\/[^/]*$/, '/');
  }

  // /////////////////////////////

  function isPinInRoute(contactId) {
    return MODEL.RoutingService.currentRouteObjects.includes(contactId);
  }

  // /////////////////////////////

  function removeStopsFromRoute(route) {
    swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, remove from route now',
      showloaderOnConfirm: true,
    })
      .then(() => {
        const entityIds = MODEL.selectedEntities.map((entity) => entity.id);
        return RoutingService.removeMultipleFromRoute(route, entityIds, window.isOnPage('accounts') ? 'account' : 'contact');
      })
      .then(() => $scope.rebuildRoute())
      .then(() => $scope.saveRoute())
      .then(() => {
        const selectedIds = MODEL.selectedEntities.reduce((result, {id}) => Object.assign(result, {[id]: true}), {});

        $scope.RoutingServiceActions.updateRouteObjects(MODEL.RoutingService.routeObjects.filter((object) => !selectedIds[object.id]));
        analyticsService.clicked(['Map', 'remove stops from route']);
        MODEL.selectedRecords = {};
        MODEL.selectedEntities = [];

        $scope.loadMap();
        MODEL.AllContactsService.deleteAllCheckbox = false;
        swal('Success!', 'These stops have been removed.', 'success');
      })
      .catch((err) => {
        if (err === 'cancel') {
          return;
        }
        return swal('Uh-Oh', "Sorry, we couldn't remove those stops. Please try again.", 'error');
      });
  }

  function updateAllottedTime(allottedTime, updateFlag) {
    this.allottedTime = allottedTime;
    if (updateFlag) {
      $scope.RoutingServiceActions.updateEditFlag({rebuildRoute: true});
    }
  }

  function renderInvalidRoutePopupHtml(routeStops, stopsWithoutCoordinates, entityType) {
    const plural = stopsWithoutCoordinates.length > 1;
    const html = $('<div class="mmc-invalid-route-popup">');
    html.append(
      `${stopsWithoutCoordinates.length > 1 ? 'These records' : 'This record'} have changed location and this route is no longer valid:
          <ol class="mmc-invalid-route-popup__list">
            ${stopsWithoutCoordinates.map(({id, name}) => `<li><a href="#/${entityType}/edit/${id}" target="_blank">${name}</a></li>`)}
          </ol>`,
    );

    const buttons = $('<div class="mmc-invalid-route-popup__buttons">');
    html.append(buttons);

    // only suggest deleting invalid stops if there are any valid stops
    if (routeStops.length > stopsWithoutCoordinates.length) {
      buttons.append(`<button class="mmc-button mmc-button--danger js-remove-stops">Remove invalid ${getEntityDisplayName(entityType, plural)} from route</button>`);
    }

    buttons.append('<button class="mmc-button mmc-button--danger js-delete-route">Delete route</button>');
    buttons.append('<button class="mmc-button mmc-button--secondary js-cancel">Cancel</button>');

    return html;
  }
}

MappingCtrl.$inject = [
  '$scope', '$route', 'MainService', 'MappingService', 'BaseController',
  'CustomerService', 'TerritoriesService', 'RoutingService', 'ImportService', 'LassoService',
  'FetchCRMDataService', 'CacheService', 'TeamService', 'AccountsService', 'AllContactsService',
  'GroupsService', 'CrmActivityTypesNetworkService', 'FunnelService', 'LeadGenService', 'mmcConst',
  '$timeout',
];
